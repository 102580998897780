var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("div", { staticClass: "head" }, [
        _c(
          "div",
          { staticClass: "avatar-container" },
          [
            _vm.product_owner
              ? _c("avatar", { attrs: { user: _vm.product_owner } })
              : _c("div", { staticClass: "avatar-spacer" }),
          ],
          1
        ),
        _c("div", [
          _c("label", [
            _c("span", [_vm._v(_vm._s(_vm.project.ref))]),
            _vm._v(" " + _vm._s(_vm.project.name) + " "),
          ]),
          _vm.project.description
            ? _c("span", { staticClass: "description" }, [
                _vm._v(" " + _vm._s(_vm.project.description) + " "),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.group.name != "active"
        ? _c("span", { staticClass: "tag desktop-only" }, [
            _vm._v(" " + _vm._s(_vm.group.name) + " "),
          ])
        : _vm._e(),
      _vm.status
        ? _c(
            "div",
            { staticClass: "status-block" },
            [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.project_status.content) + " "),
              ]),
              _c("project-status-tag", {
                attrs: { status: _vm.status, size: "mini" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }