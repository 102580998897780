var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "actions-buttons",
        {
          directives: [
            { name: "only-super-admin", rawName: "v-only-super-admin" },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "user-selector-wrapper" },
            [
              _c("user-selector", {
                attrs: {
                  users: _vm.lead_users,
                  selected: _vm.selected_users,
                  show_tooltips: true,
                  fixed_list: true,
                  single_select: true,
                },
                on: { change: _vm.handleSelectUsers },
              }),
            ],
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "desktop-only",
              staticStyle: { "margin-right": "10px", width: "280px" },
              attrs: {
                multiple: "",
                "collapse-tags": "",
                placeholder: "Select",
              },
              model: {
                value: _vm.filter_status,
                callback: function ($$v) {
                  _vm.filter_status = $$v
                },
                expression: "filter_status",
              },
            },
            _vm._l(_vm.filter_status_options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c("el-button", { on: { click: _vm.handleClickOnNewProject } }, [
            _vm._v(" New "),
          ]),
        ],
        1
      ),
      _vm._l(_vm.projects_filtered, function (group, idx) {
        return _c("div", { key: idx.toString(), staticClass: "group" }, [
          _c(
            "ul",
            { staticClass: "list" },
            _vm._l(group.projects, function (project) {
              return _c("project-list-item", {
                key: project.id,
                class: group.name + "-background",
                attrs: { group: group, project: project },
                on: {
                  click: function ($event) {
                    return _vm.handleClickOnProject(project)
                  },
                },
              })
            }),
            1
          ),
          _c("hr"),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }